import React from 'react';
import { Section } from 'components/organisms';

import { LocationList,
         Hero } from './components';

import {
  locations
} from './data';

const Locations = () => (
  <div>
    
    <Hero />
    <Section>
      <LocationList data={locations}/>
    </Section>
    
  </div>
);

export default Locations;
